import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import cn from "classnames";

import "./styles.css";

const Maintenance = ({
  backgroundTopLeft,
  backgroundBottomRight,
  title,
  text,
  points,
  secondary
}) => {
  return (
    <div
      className={cn("[ Maintenance ]", { Maintenance_secondary: secondary })}
    >
      <img
        className="Maintenance__backgroundLeft"
        src={backgroundTopLeft.publicURL}
        alt=""
      />
      <img
        className="Maintenance__backgroundRight"
        src={backgroundBottomRight.publicURL}
        alt=""
      />
      <div className="Content md:Content_small sm:Content_medium">
        <h2 className="[ Maintenance__title ] [ Font_headerBig sm:Font_headerSmall  Color_darkBlue ]">
          {title}
        </h2>
        <div className="[ Maintenance__subtitle ] [ Font_bigText Color_darkBlue ]">
          {text}
        </div>

        <div className="Maintenance__wrapper">
          {points.map(({ title, text, image }) => (
            <div key={title} className="Maintenance__item">
              <div className="Maintenance__content" key={title}>
                <Image
                  className="Maintenance__img"
                  image={image}
                  imgStyle={{ objectFit: "contain" }}
                  alt="photo"
                />
                <div className="[ Maintenance__case ] [ Font_headerSmall Color_darkBlue80 ]">
                  {title}
                </div>
                <div className="[ Maintenance__description ] [ Font_textRegular Color_darkBlue80 ]">
                  {text}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Maintenance.getLivePreviewData = ({ data, getAsset }) => ({
  ...data.maintenance,
  backgroundTopLeft: {
    publicURL: getAsset(data.maintenance.backgroundTopLeft).url
  },
  backgroundBottomRight: {
    publicURL: getAsset(data.maintenance.backgroundBottomRight).url
  },
  points: data.maintenance.points.map(point => ({
    ...point,
    image: getAsset(point.image).url
  }))
});

const maintenanceQuery = graphql`
  fragment MaintenanceFragment on MarkdownRemarkFrontmatter {
    maintenance {
      backgroundTopLeft {
        publicURL
      }
      backgroundBottomRight {
        publicURL
      }
      title
      text
      points {
        image {
          childImageSharp {
            fluid(maxWidth: 566) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        text
      }
    }
  }
`;

export { Maintenance, maintenanceQuery };
