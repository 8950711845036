import React from "react";
import cn from "classnames";

import "./styles.css";

const Stages = ({ className, stages, horizontal }) => {
  return (
    <div className={cn(className, { Stages_horizontal: horizontal })}>
      <ul className="Stages__container">
        {stages.map((stage, index) => (
          <li key={stage.name} className="Stages__stage">
            <div className="[ Stages__marker ] [ Font_textSemiBold sm:Font_smallText Color_darkBlue ]">
              {index + 1}
            </div>

            <img
              className="[ Stages__icon ]"
              src={stage.icon.publicURL}
              alt=""
            />

            <div className=" [ Stages__name ] [ Font_smallText Color_darkBlue ]">
              {stage.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
export { Stages };
