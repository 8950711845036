import React from "react";
import cn from "classnames";
import "./styles.css";

const List = ({ className, children }) => {
  return (
    <ul
      className={cn(
        className,
        "[ List ] [ Font_bigText sm:Font_textRegular Color_darkBlue80 ]"
      )}
    >
      {children}
    </ul>
  );
};

const ListItem = ({ children }) => {
  return <li className="[ List__item ]">{children}</li>;
};

export { List, ListItem };
